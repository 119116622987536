export default({
  data:()=>({
    required: v => !!v || '필수입력입니다.',
    id_rule: v => !v || (/^[a-zA-Z0-9]{4,20}$/.test(v)) || '4~20자 영문 대/소문자, 숫자를 사용해주세요.',
    email_rule: v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || '메일 형식이 올바르지 않습니다.',
    birthday_rule: v => !v || /^(19[0-9][0-9]|20\d{2})-?(0[0-9]|1[0-2])-?(0[1-9]|[1-2][0-9]|3[0-1])$/.test(v) || '날짜 형식이 올바르지 않습니다.',
    phone_rule:    v => !v || /^([0-9]{2,3})-?([0-9]{3,4})-?([0-9]{4})$/.test(v) || '전화번호 형식이 올바르지 않습니다.',
  }),
  methods:{
    password_rule:function(v){
      if ( !v || v.lenght == 0 ) return true;
      if ( !/^(?=.*[a-zA-Z])(?=.*[./!@#$%^*+=-])(?=.*[0-9]).{8,16}$/.test(v) ){
        return '8~16자의 영문, 숫자, 특수문자를 모두 사용해 주세요.';
      }
      
      const keyboard = ["1234567890", "qwertyuiop", "asdfghjkl", "zxcvbnm", "0987654321", "poiuytrewq", "lkjhgfdsa", "mnbvcxz"];
      for (let i = 0; i < v.length-2; i++) {
          const sliceValue = v.substring(i, i + 3);
          
          if (keyboard.some((code) => code.includes(sliceValue))) {
              return '연속된 문자열은 사용할 수 없습니다. (1234, qwer, fdsa 등)';
          }
      }
      let ch = null;
      let cnt = 0;
      for ( let i = 0 ; i < v.length ; i++ ){
        if ( !ch ){
          ch = v[i];
          cnt++;
        }
        else{
          if ( ch == v[i] ){
            cnt++;
          }
          else{
            ch = v[i];
            cnt = 1;
          }
        }
        if ( cnt >= 3 ){
          return '동일한 문자를 반복하여 사용할 수 없습니다. (111, aaa 등)'
        }
      }
      return true;
    }
  }
});