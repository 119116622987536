<template>
    <tinymce class="tinycss" v-if="isOpen" v-model="content" api-key="ozevv74eieqojrz1nlrjauud4st0kyr8xw6dtyse5yc4pc99" :init="init" :disabled="disabled"></tinymce>
</template>

<script>
import Tinymce from '@tinymce/tinymce-vue'

let editorId = undefined;
export default {
    name:"Editor-view",
    props:['value', 'height', 'readonly', 'inline'],
    components:{
        'tinymce': Tinymce
    },
    data:()=>({
        disabled: false,
        isOpen: false,

        init:{
            selector: '#myTextarea',
            automatic_uploads: false,
            relative_urls: false,
            convert_urls: false,
            language: 'ko_KR',
            plugins: [
            'advlist autolink link image lists charmap preview hr',
            'searchreplace visualblocks visualchars insertdatetime',
            'table emoticons paste'
            ],
            images_upload_url: '',
            image_description: false,
            image_dimensions: false,
            contextmenu: 'checkbox',
            toolbar: 'alignleft aligncenter alignright alignjustify | fontsizeselect bold italic underline |' +
            'bullist numlist outdent indent | link image checkbox | forecolor backcolor table ',
            table_toolbar:'',
            table_column_resizing:'preservetable',
            fontsize_formats: '8pt 9pt 10pt 11pt 12pt 13pt 14pt 15pt 16pt 17pt 18pt 19pt 20pt 24pt 28pt 32pt 36pt',
            content_style: 'body{font-size:10pt;}',
            setup: function(editor){
                let fontArray = this.fontsize_formats.split(' ')
                editor.ui.registry.addButton('checkbox', {
                    icon: 'selected',
                    onAction: function(){
                        let fontsize = 10
                        for ( let i = 0 ; i < fontArray.length ; i++ ){
                            if ( editor.formatter.match('fontsize', {value:fontArray[i]}) ){
                                fontsize = fontArray[i].replace('pt', '')*1
                            }
                        }
                        fontsize /= 10
                        fontsize += ''
                        editor.insertContent('<input name="checkboxs" style="zoom:' + fontsize + ';" type="checkbox"></input>')
                    },
                })
                editor.on('init', function(args){
                    editorId = args.target.id
                })
            },
            init_instance_callback:function(editor){
                editor.on('click', function(e){
                    if ( e.target.type === 'checkbox' ){
                        if ( e.target.checked )
                            e.target.setAttribute('checked', 'checked')
                        else{
                            e.target.removeAttribute('checked')
                        }
                        
                    }
                })
            }
        },
        content:'',
    }),
    created:function(){
        editorId = undefined
        if ( this.height ){
            this.init.height = this.height
        }
        if ( this.value ){
            this.content = this.value.replace(/\\/g,'')
        }
        if ( this.readonly ){
            this.init.plugins = []
            this.init.toolbar = []
            this.init.menubar = []
            this.disabled = true
            let interval = setInterval(()=>{
                if ( editorId ){
                    let t = document.getElementsByName('checkboxs')
                    for ( let i = 0 ; i < t.length ; i++ ){
                        t[i].setAttribute('onclick', 'return false')
                    }
                    clearInterval(interval)
                }
            }, 10)
        }
        if ( this.inline ){
            this.init.inline = true
        }
    },
    mounted:function(){
        this.isOpen = true
    },
    watch:{
        content:function(val){
            this.$emit("commitContent", val)
        },
    }
}
</script>

<style scoped>
@import '../../assets/content.css';
</style>