import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'

import router from './router'
import {store} from "./store"
import lodash from 'lodash'
import VueSession from 'vue-session'
import VueCookies from 'vue-cookies'
import VueJWT from 'vuejs-jwt'

Vue.config.productionTip = false

Vue.prototype.lodash = lodash;

Vue.prototype.$rootUrl = (process.env.NODE_ENV==='development')?"http://lims.hlscience.com":""

var sessionOption = {
  persist: false
}
Vue.use(VueJWT)
Vue.use(VueSession, sessionOption)
Vue.use(VueCookies);

VueCookies.config('1y');

new Vue({
  vuetify,
  router,
  store: store,
  render: h => h(App)
}).$mount('#app')
